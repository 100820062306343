import "./Packages.css";

const Packages = () => {
  return (
    <div id="packages">
      <div className="introduction">
        <h1>Packages</h1>
        <p>
          Our company specializes in providing backend services to computer
          retailers, IT consultants, integrators, and developers. We understand
          that these businesses often have clients with unique needs, and we
          strive to provide solutions that complement and enhance their
          offerings. By providing these services, we aim to allow you to expand
          the products and services to your clients easily and quickly.
        </p>
        <p>
          We design customized packages for our clients that can be tailored to
          meet their specific needs. These packages can include a wide range of
          features such as website maintenance, social media management, social
          media content creation, internet marketing and security. The nature of
          the inclusions is a matter of negotiation between us and our client,
          as we work closely with them to understand their needs and the needs
          of their own clients.
        </p>
        <p>
          Our team of experts has extensive experience in the field, and we are
          constantly updating our knowledge and skills to stay ahead of the
          latest trends and technologies. We are committed to providing the
          highest level of customer service. Our team is available to provide
          support and assistance whenever you need it, and we are always happy
          to answer any questions you may have. We understand that your business
          is unique, and we strive to provide solutions that are tailored to
          your specific needs.
        </p>
        <p>
          Our company is dedicated to helping our clients succeed. We understand
          the importance of reliable and efficient backend services, and we are
          committed to providing the best solutions for our clients. Whether you
          are a computer retailer, IT consultant, integrator, or developer, we
          have the expertise and resources to help you meet the needs of your
          clients. With our help, you can easily and quickly expand the products
          and services you offer to your own clients, with features such as
          website maintenance, social media management, social media content
          creation, and internet marketing.
        </p>
      </div>
      <div id="hosting">
        <h2>Example: Static Website and Marketing Packages</h2>
        <p>All of our "static hosting" options include:</p>
        <ul>
          <li>
            A detailed review of your current website, social media and online
            advertising.
          </li>
          <li>
            A design update and application of a consistent theme across all
            your online presence.
          </li>
          <li>
            Hosting of your domain, your secure website and full secure backup
            of your website.
          </li>
          <li>
            An ad is a single ad on one platform. Either: Facebook, Instagram,
            Google Ads or YouTube.
          </li>
          <li>
            All contracts are 24 months. Items not used in a month are not
            rolled over.
          </li>
          <li>Monthly fees do not include the cost of the advertising.</li>
        </ul>
      </div>
      <div className="pkg_grp">
        <div className="pkg_one">
          <ul>
            <li className="pkg_header">Basic</li>
            <li>Single Page React Site</li>
            <li className="pkg_price">$240 / Month </li>
            <li className="pkg_subheader">Ad Campaigns</li>
            <li>4 Managed Ads</li>
            <li>-</li>
            <li>-</li>
            <li>Monthly Report</li>
            <li className="pkg_subheader">Website</li>
            <li>30 Min Updating</li>
            <li>-</li>
            <li>-</li>
            <li className="pkg_subheader">Facebook</li>
            <li>4 Posts</li>
            <li>-</li>
            <li className="pkg_subheader">Instagram</li>
            <li>4 Posts</li>
          </ul>
        </div>
        <div className="pkg_two">
          <ul>
            <li className="pkg_header">Business</li>
            <li>4 Page React Site</li>
            <li className="pkg_price">$510 / Month </li>
            <li className="pkg_subheader">Ad Campaigns</li>
            <li>10 Managed Ads</li>
            <li>1 Landing SPA</li>
            <li>Weekly Activity Review</li>
            <li>Monthly Report</li>
            <li className="pkg_subheader">Website</li>
            <li>60 Min Updating</li>
            <li>Contact Form</li>
            <li>400 Email Replies</li>
            <li className="pkg_subheader">Facebook</li>
            <li>10 Posts</li>
            <li>400 Messenger Replies</li>
            <li className="pkg_subheader">Instagram</li>
            <li>10 Posts</li>
          </ul>
        </div>
        <div className="pkg_three">
          <ul>
            <li className="pkg_header">Growth</li>
            <li>8 Page React Site</li>
            <li className="pkg_price">$980 / Month </li>
            <li className="pkg_subheader">Ad Campaigns</li>
            <li>20 Managed Ads</li>
            <li>2 Landing SPA</li>
            <li>Daily Activity Review</li>
            <li>Monthly Report</li>
            <li className="pkg_subheader">Website</li>
            <li>120 Min Updating</li>
            <li>Contact Form</li>
            <li>900 Email Replies</li>
            <li className="pkg_subheader">Facebook</li>
            <li>20 Posts</li>
            <li>900 Messenger Replies</li>
            <li className="pkg_subheader">Instagram</li>
            <li>20 Posts</li>
          </ul>
        </div>
      </div>
      <p>
        IOW offers a white label relationship with our clients for their
        customers. In this relationship, our clients act as the direct point of
        contact for support and hold the relationship with their customers. They
        determine the content of the packages in consultation with IOW, manage
        their customers' needs and handle any customer inquiries. IOW is
        responsible for delivering the services at a wholesale rate and ensuring
        that they are of the highest quality. We guarantee that all services are
        delivered to the highest possible standards.
      </p>
    </div>
  );
};

export default Packages;
