import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import Marketing from "./components/marketing/Marketing";
import Development from "./components/development/Development";
import Services from "./components/services/Services";
import Packages from "./components/packages/Packages";
import NotFound from "./components/errorpages/NotFound";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <div id="contents">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/internet-marketing" element={<Marketing />} />
            <Route path="/bespoke-development" element={<Development />} />
            <Route path="/specialist-services" element={<Services />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
