import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../../assets/logo.svg";
import { FaPhoneAlt, FaEnvelope, FaGlobe } from "react-icons/fa";

const Footer = () => {
  return (
    <section id="footer">
      <div className="container u-text-light">
        <div id="footer-main">
          <div id="footer-left">
            <Link to="/">
              <div className="imgbox">
                <img
                  src={logo}
                  alt="Speedy Global Logo White"
                  aria-hidden="true"
                />
              </div>
            </Link>
            <p>IOW PTY LTD &copy; 1999-2023</p>
            <hr />
          </div>
          <div id="footer-right">
            <div id="footer-contact">
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaPhoneAlt /> &nbsp; Phone:
                </div>
                <div className="footer-row-right">+61 7 3868 1489</div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaEnvelope /> &nbsp; Email:
                </div>
                <div className="footer-row-right">info@iow.com.au</div>
              </div>
              <div className="footer-row">
                <div className="footer-row-left">
                  <FaGlobe /> &nbsp; Website:
                </div>
                <div className="footer-row-right">www.iow.com.au</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
