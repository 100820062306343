import "./Services.css";

const Services = () => {
  return (
    <div id="services">
      <div className="introduction">
        <h1>Specialist Services</h1>
        <p>
          We are a company that has been providing internet-based services for
          many years. We have a wide range of internal resources as well as
          contractors who can handle just about anything that is possible on
          internet protocols. Over the years, we have done a variety of
          different projects and have developed a wide range of expertise.
        </p>
        <p>
          Our main focus has always been on UNIX operating systems, including
          IRIX, Solaris, FreeBSD, Linux (Debian/Red Hat), and others. However,
          as technology has evolved, we have also adapted to new trends such as
          cloud-based containers and microservice designs. Our expertise in UNIX
          systems has been invaluable in helping us to understand and work with
          these new technologies.
        </p>
        <p>
          We have extensive experience in working with complex TCP/IP networks
          and have used a variety of different types of bridges, including
          radio, microwave, and laser. We have mostly worked with CISCO
          equipment and have a deep understanding of how to configure and
          troubleshoot their systems. Our first terminal server was Stallion,
          and we remember when people dialed in on 28.8 modems and had to ask
          for a PPP connection. It was a huge step forward when we put in our
          first 2 PRIs and got a Cisco with a bucket of 56K DSPs.
        </p>
        <p>
          Being so involved with the internet has also meant that we have been
          heavily involved in security. We have been helping clients with
          Windows 95 update their machines before connecting to the web since
          the beginning of internet. We have built firewalls for highly
          sensitive networks, monitored systems and audited systems for
          potential vulnerabilities. We have a strong understanding of the
          latest security threats and how to protect against them. We have
          helped our clients to keep their systems secure and to prevent
          unauthorized access.
        </p>
        <p>
          We have also been involved in a wide range of different projects over
          the years. We have worked with clients in a variety of different
          industries and have helped them to solve a wide range of different
          problems. Our team is made up of experts with a wide range of
          different skills and we are always willing to take on new challenges.
          We are confident that we can find a solution to any problem that you
          might have.
        </p>
        <p>
          In summary, we are a company that has a lot of experience in working
          with internet protocols and have a deep understanding of UNIX
          operating systems. We have worked with a wide range of different
          technologies and have a lot of expertise in different areas. We have
          been heavily involved in security and have helped our clients to keep
          their systems safe. We are always willing to take on new challenges
          and are confident that we can find a solution to any problem that you
          might have. If you have a complex problem that needs solving, let us
          know and we'll do our best to find a solution.
        </p>
      </div>
    </div>
  );
};

export default Services;
