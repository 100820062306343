import { useState } from "react";
import { Link } from "react-router-dom";

import { AiOutlineBars } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";

import logo from "../../assets/logo.svg";
import "./Navbar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const closeMenu = () => {
    setShowMenu(false);
  };
  return (
    <div className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="IOW Logo" />
        </Link>
      </div>
      <div
        id={showMenu ? "navbar-menu-small" : "navbar-menu-large"}
        className="navbar-menu"
      >
        <div
          id={showMenu ? "navbar-menu-close" : "navbar-menu-open"}
          className="navbar-menu-links"
        >
          <ul>
            {showMenu && (
              <li>
                <Link to="/" onClick={closeMenu}>
                  Home
                </Link>
              </li>
            )}
            <li>
              <Link to="/internet-marketing" onClick={closeMenu}>
                Internet Marketing
              </Link>
            </li>
            <li>
              <Link to="/bespoke-development" onClick={closeMenu}>
                Bespoke Development
              </Link>
            </li>
            <li>
              <Link to="/specialist-services" onClick={closeMenu}>
                Specialist Services
              </Link>
            </li>
            <li>
              <Link to="/packages" onClick={closeMenu}>
                Packages
              </Link>
            </li>
          </ul>
        </div>
        <div
          id={showMenu ? "icon-close" : "icon-open"}
          className="navbar-menu-icons"
          onClick={toggleMenu}
        >
          {showMenu ? (
            <RiCloseLine color="#333" size={33} />
          ) : (
            <AiOutlineBars color="#333" size={33} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
