import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./Home.css";

const Home = () => {
  return (
    <div id="home">
      <div id="home-logo">
        <img src={logo} alt="IOW Logo" />
      </div>
      <div id="home-top">
        <div id="home-info">
          <h1>We have been doing internet our way since 1994!</h1>
          <p>
            The only constant with the world wide web is change. We began when
            web pages were gray, text was black, and hyperlinks were the main
            feature. We remember Gopher, Archie, and Webcrawler. We have built
            websites for small local businesses and multinational companies. We
            have also consulted, designed, and set up highly secure intranets,
            extranets, LANs, and WANs for companies that needed to protect
            sensitive data.
          </p>
          <p>
            We prefer UNIX server systems and have worked with all major
            internet technologies, services, and protocols: SMTP, POP, IMAP,
            DNS, SSL, HTTP, FTP, you name it. We have decades of experience
            coding in HTML, CSS, Javascript, Python, PHP, C, and just about
            every trend, like VRML and WAP, that has come along.
          </p>
          <p>
            They say that if you learn enough history, you can predict the
            future. We have been involved in the internet for nearly three
            decades, and we are already seeing trends return. For example, we
            started with single page designs in the 1990s for websites. Then,
            websites "had" to be bigger, and we had to consider menu structures
            to ensure people could get to information quickly. Websites became
            complex and needed regular updating. Then, websites needed to embed
            "news" to keep customers coming back. Now, we are back to
            recommending single page designs for company websites again.
          </p>
          <p>
            We are experts with relational databases, particularly MySQL and
            more recently, Amazon Aurora. We can develop, and have, massive
            internet-based applications with thousands of tables in the schema,
            using Object Oriented PHP and other languages as needed. In recent
            years, we have embraced Amazon Web Services (AWS) and it is changing
            the web again.
          </p>
          <p>
            AWS Cloud opens up a massive opportunity for businesses of all
            sizes. We are working extensively with CI/CD, noSQL, and
            microservices designs now. It is a fantastic option for many
            application designs and redesigns in the modern context. The main
            advantage is that you only pay for what you use. There are no
            massive hardware investments for the business. We have expertise and
            experience with GIT, Amazon Simple Email Service, IAM, Certificate
            Manager, CloudFront, CloudWatch, CodeCommit, CodePipeline, DynamoDB,
            EC2, Elastic Container Services, Fargate, Lambda, RDS, Route53, S3,
            and Simple Notification Service. If there is an AWS service you need
            help with, it is likely we can assist you. We are currently working
            on projects involving Internet of Things and Machine Learning.
          </p>
          <p>
            Our international team can solve nearly any internet, data, network,
            or application need you might have. We provide services that help
            businesses of all sizes. If we haven't talked about it here, just
            ask us, chances are we can help. Our fees are competitive and we
            stand by all of our work. From complex network structures and
            monitoring of secure data to a small single page site for a business
            that just needs a landing page, we can do it! Some of the services
            we provide include:
          </p>
        </div>
      </div>
      <div id="homeCards">
        <div className="homeCard">
          <div className="homeHeading">
            <h2>
              <Link to="/internet-marketing">Internet Marketing</Link>
            </h2>
          </div>
          <div className="homeBody">
            <p>
              If you need help keeping all the requirements for your web
              presence up to date, we can help. We can start by ensuring the
              image you want to project online is what you are actually
              projecting. We can update and maintain your website, create
              special promotional landing pages and manage all of your social
              media accounts. Additionally, we can help you analyze your
              marketing efforts, develop marketing strategies, and act as a
              first responder to potential customers by quickly responding to
              their inquiries and filtering out the serious requests from the
              rest, so you only have to deal with things that make you money.
              Keeping up with the rapid changes in the digital landscape can be
              challenging, but with IOW, you'll have the support you need to
              stay professional and competitive in your field.
            </p>
          </div>
        </div>
        <div className="homeCard">
          <div className="homeHeading">
            <h2>
              <Link to="/bespoke-development">Bespoke Development</Link>
            </h2>
          </div>
          <div className="homeBody">
            <p>
              Do you need an application that aligns with your business
              operations? Do you have an idea for an application that could be
              successful? Are you missing someone reliable to bring it to
              fruition? Look no further. We have a dedicated team with decades
              of experience who can create anything that can be done on the
              internet. We have a special interest in projects that fully
              utilize Cloud technology, as we believe microservices are the
              future. However, we are not limited to one technology and
              understand that certain projects may require SQL or complex
              monolithic applications instead of microservices. We can discuss
              these options when you tell us more about your project and desired
              outcome. If it matters to you, it matters to us.
            </p>
          </div>
        </div>
        <div className="homeCard">
          <div className="homeHeading">
            <h2>
              <Link to="/specialist-services">Specialist Services</Link>
            </h2>
          </div>
          <div className="homeBody">
            <p>
              Our founder was coding on an Apple II in the early 1980s and
              completed his first major program for a pathology laboratory in
              1987. He established his first ISP in 1994 and has worked with
              some of the top professionals in the industry since then. He may
              not be able to do everything, but he knows someone who can. IOW
              can provide a solution for nearly any problem you may have. Our
              "specialist services" include a wide range of offerings. If you
              need a complex, highly secure private network utilizing microwave
              bridges, laser bridges, radio bridges and mobile phones to run
              SCADA and access critical equipment, we can set it up. In fact,
              we've done it before.
            </p>
          </div>
        </div>
      </div>
      <div id="home-bottom">
        <h2>Special Note</h2>
        <h3>Wholesale / White label</h3>
        <p>
          We provide services to other computer and IT companies, but not
          typically to end-users. We are the go-to for companies who need a
          solution to a complex problem or want to offer internet services to
          their customers that they currently don't offer. If it's
          internet-related, we can help, and we don't need to do it under our
          own brand.
        </p>
        <p>
          If you're interested in "white labeling" our services, please give us
          a call. We'll handle the work, while you consult with the customer.
          Your customer will receive a great result without ever knowing we were
          involved. You don't have to use all of our services, if you just want
          to sell one or two, that's fine too. Again, you consult with your
          customer, we handle the work behind the scenes, and everyone is happy.
        </p>
      </div>
    </div>
  );
};

export default Home;
