import twelveFactor from "../../assets/twelve_factor.png";
import "./Development.css";

const Development = () => {
  return (
    <div id="development">
      <div className="introduction">
        <h1>Bespoke Development</h1>
        <p>
          Our company has a long history of working with computers and software
          development. In the early-mid 1980s, our owner began experimenting
          with computers and in 1987, he developed his first major computer
          program for a pathology laboratory. It wasn't until 1994, with the
          advent of the Netscape browser, that he fully dedicated himself to
          application development and internet by starting his first ISP.
        </p>

        <p>
          From the mid-90s to the present, we have developed a wide range of
          websites and web-based applications. We started integrating databases
          in 1996, which allowed us to create dynamic sites for large companies.
          In 1999, after selling the ISPs, we developed our first major online
          application called "IntegraStock", which was a product that analyzed
          the stock market and produced indicators and charts. This product was
          sold in Australia, Hong Kong, and Singapore.
        </p>

        <p>
          Over the years, we have developed a wide range of bespoke applications
          using various internet languages and frameworks. We specialize in the
          classics such as HTML, CSS, Javascript, PHP, and MySQL. Recently, we
          have embraced the cloud, specifically AWS. We are experienced in using
          services such as CodeCommit, CodePipeline, EC2/ECS, Lambda, and
          DynamoDB. Additionally, we are familiar with other AWS services like
          Route 53, Amplify, CloudFront, and more.
        </p>

        <p>
          Our team includes staff members who were not born when we developed
          our first application and those who were adults when the World Wide
          Web was first introduced. With a wealth of experience and knowledge,
          we are well-equipped to provide solutions for any application
          development needs.
        </p>
      </div>
      <div className="mainBox">
        <div className="mainBoxHeading">
          <h2>Modern Development Methods</h2>
        </div>
        <div className="mainBoxInfo">
          <div className="mainBoxBody">
            <p>
              We follow the twelve-factor app methodology for building
              software-as-a-service applications for our clients. The
              twelve-factor app methodology is a set of best practices for
              building and deploying software-as-a-service (SaaS) applications.
              These guidelines were first introduced by Adam Wiggins in 2011,
              and have since become a widely accepted standard for building
              modern, cloud-native applications. The twelve factors are as
              follows:
            </p>

            <ol>
              <li>
                <strong>Codebase:</strong> The codebase should be stored in a
                revision control system and should be easily deployable to
                different environments.
              </li>
              <li>
                <strong>Dependencies:</strong> All dependencies should be
                explicitly declared and isolated, rather than relying on
                implicit dependencies. This makes it easier to manage and update
                dependencies.
              </li>
              <li>
                <strong>Config:</strong> Configuration should be stored in the
                environment, rather than in the codebase. This allows for
                different configurations to be used in different environments,
                such as development, staging, and production.
              </li>
              <li>
                <strong>Backing Services:</strong> Backing services, such as
                databases and queues, should be treated as attached resources,
                rather than being tightly integrated into the application. This
                allows for different backing services to be used in different
                environments and makes it easier to scale and update the
                services.
              </li>
              <li>
                <strong>Build, Release, Run:</strong> The build, release, and
                run stages of the application should be strictly separated. This
                allows for easy rollbacks and makes it easier to test and deploy
                new versions of the application.
              </li>
              <li>
                <strong>Processes:</strong> The application should be executed
                as one or more stateless processes, rather than relying on
                stateful processes. This makes it easier to scale and deploy the
                application.
              </li>
              <li>
                <strong>Port Binding:</strong> The application should be able to
                export services via port binding, rather than being tightly
                integrated into the infrastructure. This allows for the
                application to be deployed on different environments, such as
                cloud and on-premises.
              </li>
              <li>
                <strong>Concurrency:</strong> The application should be able to
                scale out via the process model, rather than relying on a single
                process or thread.
              </li>
              <li>
                <strong>Disposability:</strong> The application should be able
                to start and stop quickly and gracefully, making it more
                resilient to failures.
              </li>
              <li>
                <strong>Dev/Prod Parity:</strong> The development, staging, and
                production environments should be as similar as possible, to
                minimize differences between environments.
              </li>
              <li>
                <strong>Logs:</strong> Logs should be treated as event streams,
                rather than being written to a single file. This allows for
                easier analysis of the logs.
              </li>
              <li>
                <strong>Admin Processes:</strong> Administrative tasks should be
                run as one-off processes, rather than being tightly integrated
                into the application.
              </li>
            </ol>

            <p>
              By following the twelve-factor methodology, our developers can
              build applications that are easy to deploy, scale, and maintain.
              The methodology promotes the use of standard practices and
              technologies, such as containerization and cloud-native
              architectures, which make it easier to deploy and run applications
              in modern environments. It also promotes the use of declarative
              configuration, which makes it easier to manage and update
              applications over time.
            </p>

            <p>
              The twelve-factor methodology can be applied to apps written in
              any programming language and using any combination of backing
              services. Using the Amazon Web Services (AWS) ecosystem, for
              example, our developers can apply the most appropriate language
              and services for the task at hand.
            </p>
            <p>
              In conclusion, The twelve-factor app methodology is an important
              set of guidelines for building and deploying SaaS applications. It
              helps developers create applications that are easy to deploy,
              scale, and maintain, while promoting the use of standard practices
              and technologies. By following these guidelines, developers can
              build applications that are suitable for deployment in modern,
              cloud-native environments, making it easy to deploy and run
              applications on different platforms.
            </p>
            <p>
              AWS provides a variety of services that align with the principles
              of the twelve-factor methodology, making it easy for developers to
              build and deploy scalable, resilient, and maintainable
              applications. By using AWS to implement the twelve-factor app
              methodology, developers can take advantage of the platform's
              capabilities and build high-quality, cloud-native applications.
            </p>

            <p>
              If you want to know more:{" "}
              <a
                href={"https://12factor.net/"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                https://12factor.net/
              </a>
            </p>
          </div>
          <div className="mainBoxImage">
            <img src={twelveFactor} alt="Twelve Factor Method Images" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Development;
