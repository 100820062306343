import design from "../../assets/design.png";
import facebook from "../../assets/facebook-bg.png";
import google from "../../assets/google-bg.png";
import instagram from "../../assets/instagram-bg.png";
import linkedin from "../../assets/linkedin-bg.png";
import twitter from "../../assets/twitter-bg.png";
import youtube from "../../assets/youtube-bg.png";
import "./Marketing.css";

const Marketing = () => {
  return (
    <div id="marketing">
      <div className="introduction">
        <h1>Internet Marketing</h1>
        <p>
          IOW offers a full end-to-end solution for your web presence. We have
          been building websites since 1994 and have a team of designers,
          graphic artists, copywriters, social media specialists and programmers
          to help you create a consistent and professional online presence for
          your business. Our approach is tailored to each business, as
          strategies that worked in the past may no longer be effective. We work
          closely with you to understand your business and create a strategy
          that will help you take full advantage of the internet to benefit your
          business. Contact us to learn more about our services and how we can
          help you.
        </p>
      </div>
      <div className="mainBox">
        <div className="mainBoxHeading">
          <h2>Design and Develpment</h2>
        </div>
        <div className="mainBoxInfo">
          <div className="mainBoxBody">
            <p>
              There are several factors that need to be considered when setting
              up or updating your internet presence and marketing strategy. One
              of the biggest mistakes businesses make is not being consistent.
              They set up a website using a website builder and a standard
              template, which may not effectively convey the business to the
              viewer. Additionally, they set up their Facebook page and grab a
              photo from Google Images, giving a completely different impression
              to the viewer. People do judge a business by its website, and it
              is important to ensure that it works well on mobile devices.
            </p>
            <p>Other important questions to consider include:</p>
            <ul>
              <li>
                Are you projecting the right image for your business to the
                world?
              </li>
              <li>Is your presence professional and achieving your goals?</li>
              <li>
                Does your marketing reach the right people and are you making
                sales?
              </li>
              <li>
                Do you track the performance of your web presence (on all
                platforms)?
              </li>
              <li>
                Do you read online feedback and reply quickly to people who post
                to you?
              </li>
            </ul>
            <p>
              It is one thing to produce a good and consistent web presence, but
              the real trick is persistence. If you are not able to keep your
              presence up-to-date, it can work against you. This means you have
              to regularly update, post, and advertise.
            </p>
            <p>
              IOW has the solution for you! We have all the professionals needed
              to take the pressure off of you. We can design your online image
              so it is consistent and effective. We can do your posts, write the
              copy, do the adverts and answer the general messages. It does not
              cost as much as you might think.
            </p>
          </div>
          <div className="mainBoxImage">
            <img src={design} alt="Web Design Banner" />
          </div>
        </div>
      </div>
      <div id="marketingCards">
        <div id="facebook" className="marketingCard">
          <div className="marketingSection">
            <div className="cardHeading">
              <h2>Facebook</h2>
              <img src={facebook} alt="Facebook Banner" />
            </div>
            <div className="cardBody">
              <p>
                Facebook is still the dominant tool for providing regular
                updates and having a "conversation" with your clients and
                potential clients. However, if you choose to use Facebook, it's
                important to be prepared to post regularly, at least once a
                week. If a person visits your Facebook page and sees that the
                last post was a year ago, it can negatively impact their
                perception of your business. If you're not committed to
                regularly posting, it's better not to have a Facebook page at
                all.
              </p>
              <p>
                IOW can assist you with all aspects of your Facebook presence,
                from visual presentation to writing posts and answering
                messages. We offer a complete service to help you use Facebook
                effectively and maintain a consistent and professional presence.
                If you're not able to commit to regular posting, we can help you
                decide whether to shut down your Facebook page or find other
                ways to use social media.
              </p>
            </div>
            <div className="cardFooter">
              <p>Use it or loose it (shut it down)!</p>
            </div>
          </div>
        </div>
        <div id="google" className="marketingCard">
          <div className="marketingSection">
            <div className="cardHeading">
              <h2>Google</h2>
              <img src={google} alt="Google Banner" />
            </div>
            <div className="cardBody">
              <p>
                Google has a number of tools that can be valuable for marketing
                your business online, but many businesses don't use them or use
                them improperly. IOW can help you get the most out of these
                tools. We know how to use them effectively to help you achieve
                your marketing goals.
              </p>
              <p>
                Advertising with Google can be cost-effective, but if not done
                properly, it can yield little to no return on investment. It's
                important to design ads that reach the right audience and track
                their performance regularly to make sure you're getting the most
                out of your advertising spend. IOW can help you design,
                implement and monitor Google ads, and optimize them for maximum
                effectiveness. We can also help you develop strategies to reduce
                waste and improve the performance of your promotions.
              </p>
              <p>
                If you're not using Google tools and monitoring your ads daily,
                you may be wasting your money. Contact us to learn more about
                how we can help you use these tools to benefit your business.
              </p>
            </div>
            <div className="cardFooter">
              <p>
                If you are not using the tools and monitoring your ads daily you
                are probably wasting your money.
              </p>
            </div>
          </div>
        </div>
        <div id="Instagram" className="marketingCard">
          <div className="marketingSection">
            <div className="cardHeading">
              <h2>Instagram</h2>
              <img src={instagram} alt="Instagram Banner" />
            </div>
            <div className="cardBody">
              <p>
                Instagram is a photo and video platform that can be beneficial
                for businesses that want to showcase their products or services
                through visual presentation. It is different from Facebook and
                while you can connect your Facebook account to your Instagram
                account, it doesn't mean you should have both.
              </p>
              <p>
                Like Facebook, you should only use Instagram if you have a
                compelling reason and are committed to regularly posting new and
                interesting visuals, at least once a week. Before joining
                Instagram, make an informed decision about whether it will help
                promote your business or not.
              </p>
              <p>
                At IOW, we can help you make the most of Instagram by providing
                graphic design services to make your posts more visually
                appealing, adding watermarks, and assessing the benefit of each
                post to your business. Most importantly, we can also monitor and
                respond to comments on your posts, which is crucial as comments
                on social media can make or break your business reputation.
              </p>
            </div>
            <div className="cardFooter">
              <p>
                Comments on social media can make or break your business - don't
                ignore them.
              </p>
            </div>
          </div>
        </div>
        <div id="linkedin" className="marketingCard">
          <div className="marketingSection">
            <div className="cardHeading">
              <h2>LinkedIn</h2>
              <img src={linkedin} alt="LinkedIn Banner" />
            </div>
            <div className="cardBody">
              <p>
                LinkedIn is a platform that can be useful for businesses, but it
                has a different purpose than other platforms. While other
                platforms are mainly used to promote your business to customers
                and potential customers, LinkedIn is more focused on business
                connections and networking.
              </p>
              <p>
                LinkedIn can be a valuable tool for businesses in certain
                industries, such as manufacturing or wholesale, as it can
                increase your influence and network within a market sector. It
                can also be more targeted than general advertising for specific
                staff, making it helpful for businesses looking for a specific
                type of person or skill. Because of the nature of the platform,
                you can often find someone who knows the person you are
                interested in and can give you an honest assessment of them.
              </p>
              <p>
                In summary, LinkedIn should be considered as a way to improve
                your profile and business network within your industry. It's
                important to present your business consistently and accurately
                on the platform.
              </p>
            </div>
            <div className="cardFooter">
              <p>
                LinkedIn should be considered to improve your profile and
                business network within your industry.
              </p>
            </div>
          </div>
        </div>
        <div id="twitter" className="marketingCard">
          <div className="marketingSection">
            <div className="cardHeading">
              <h2>Twitter</h2>
              <img src={twitter} alt="Twitter Banner" />
            </div>
            <div className="cardBody">
              <p>
                Twitter is a platform that can be useful for businesses that
                have a lot of information, often short and time-sensitive, that
                they want to share regularly with an audience. Short,
                informative messages are the key to success on Twitter. It can
                be an effective tool for improving customer retention and
                increasing customer loyalty, as it allows you to demonstrate
                your authority on a subject and share your expertise.
              </p>
              <p>
                As with any platform, it's important to be committed to
                regularly using Twitter if you decide to have a business
                account. If you don't have anything to say and have not posted
                in a long time, it's better not to have an account.
              </p>
              <p>
                IOW can help you with all aspects of your Twitter presence, from
                writing and sending out regular tweets on your behalf, to
                monitoring your account and filtering out the noise, so you and
                your staff can focus on valuable questions and inquiries. We can
                help you communicate effectively on Twitter and use it to
                benefit your business without overwhelming you with unnecessary
                noise.
              </p>
            </div>
            <div className="cardFooter">
              <p>
                Let us filter our the noise so you can do what makes you money.
              </p>
            </div>
          </div>
        </div>
        <div id="youtube" className="marketingCard">
          <div className="marketingSection">
            <div className="cardHeading">
              <h2>YouTube</h2>
              <img src={youtube} alt="YouTube Banner" />
            </div>
            <div className="cardBody">
              <p>
                YouTube is a video-centric platform that can be useful for
                businesses to provide detailed information on products or
                services, demonstrate how to use a product, answer frequently
                asked questions, provide cost-effective training to a large
                audience or give a review of a product or service. Because
                YouTube videos are available to people at any time, they make an
                excellent way of communicating with people and providing
                information, even when your business is not open.
              </p>
              <p>
                Like all other platforms, it's important to ensure that your
                YouTube videos reflect your business accurately. IOW can assist
                you in producing good quality, informative videos for YouTube. A
                well-produced video can save you time and money by increasing
                the effectiveness of your communication and marketing efforts.
              </p>
            </div>
            <div className="cardFooter">
              <p>
                Sometimes a good video can make you money or save you time, or
                both.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
